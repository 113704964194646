import { cloneDeep } from "lodash";

import api from "apiSingleton";

export function updateHitFieldByKey(hits, hitId, fieldKey, value) {
  const clonedHits = cloneDeep(hits);
  const hitIndex = clonedHits.findIndex((h) => h.id === hitId);
  const hit = cloneDeep(hits[hitIndex]);

  hit[fieldKey] = value;
  clonedHits[hitIndex] = hit;

  return clonedHits;
}

export function replaceHit(hit, hits) {
  const clonedHits = cloneDeep(hits);
  const existingHitIndex = clonedHits.findIndex((el) => el.id === hit.id);

  clonedHits[existingHitIndex] = { ...clonedHits[existingHitIndex], ...hit };

  return clonedHits;
}

export async function updateHit(args) {
  try {
    const updatedMatch = await api.user.update({
      ...args,
    });

    return updatedMatch.data?.updateUser || {};
  } catch (error) {
    console.log("updateHit error: ", error);
  }
}

export function removeLocationFilter(locationFilter, attribute) {
  if (attribute === "countryName") {
    delete locationFilter[attribute];
    delete locationFilter["cityName"];
    delete locationFilter["stateName"];
  }
  if (attribute === "stateName") {
    delete locationFilter[attribute];
    delete locationFilter["cityName"];
  }
  if (attribute === "cityName") {
    delete locationFilter[attribute];
  }

  delete locationFilter.lat;
  delete locationFilter.lng;
  delete locationFilter.aroundRadius;
}

export function getLocationFilterStr({ stateName, cityName }) {
  const states = stateName && `location.stateName:"${stateName}"`;

  const cities = cityName && `location.cityName:"${cityName}"`;

  let filter = "";
  if (states?.length > 0) {
    filter += ` AND (${states})`;
  }

  if (cities?.length > 0) {
    filter += ` AND (${cities})`;
  }

  return filter;
}

export async function getJobOpportunityAISkillsForSearch(id, noCache) {
  return await api.jobs.getAISkillsForSearch({ id, noCache });
}
