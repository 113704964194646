import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useLocation, Link, NavLink } from "react-router-dom";

import HumpImg from "images/hump.svg";
import styles from "./Header.module.css";
import HeaderLoginButton from "./HeaderLogin";
import AppLogo from "components/Logo";
import { ModalContext } from "context/providers";

/**
 * The header component.
 *
 * @param {React.ReactElement} pageHeader Page-specific header component renderprop.
 * @param {HTMLElement.Style} backgroundStyle Custom background style object.
 * @param {boolean} hasHump Whether to render a white hump (for signup pages).
 * @param {string} className Custom class name(s).
 * @returns
 */

const Header = ({
  pageHeader,
  backgroundStyle,
  hasHump,
  className,
  hideMenu,
  signOut,
  user,
  jobOpp,
  isAuthorized,
}) => {
  const { showModal } = useContext(ModalContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  const sideBarRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    // Add event listener to close dropdown when clicking outside
    const handleOutsideClick = (e) => {
      if (
        isSidebarOpen &&
        sideBarRef.current &&
        !sideBarRef.current.contains(e.target) &&
        !menuButtonRef.current.contains(e.target)
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isSidebarOpen]);

  const gotToLink = (to, label, alt) => (
    <Link alt={alt || "link"} to={to} className="hover:text-gray-300">
      {label}
    </Link>
  );

  return (
    <div
      className={classNames("w-full relative", className)}
      style={backgroundStyle}
    >
      <div className={classNames("w-full", styles.header)}>
        <div className="mx-12 mt-3" style={{ gridArea: `logo` }}>
          <Link alt="link to home if logged in or home if logged out" to="/">
            <AppLogo />
          </Link>
        </div>

        <div
          className="bg-header pl-10 pr-8 p-2 self-stretch ml-8 md:ml-0 justify-end sm:justify-between flex h-16 items-center"
          style={{
            clipPath: `polygon(0% 0%, 45px 100%, 100% 100%, 100% 0%)`,
            gridArea: `user`,
          }}
        >
          {isAuthorized ? (
            <div className="ml-16 text-white gap-x-4 hidden sm:flex">
              {gotToLink("/", "Job Search", "link to root")}
              {gotToLink(
                "/usermanager",
                "Talent Pool",
                "link to usermanager page"
              )}
              {user.canAccessResumeProfileTalentCreation &&
                gotToLink(
                  "/talent-creation",
                  "Talent Creation",
                  "Link to talent-creation page"
                )}
              {jobOpp?.id && (
                <div className="flex gap-x-4 items-center">
                  <div className="bg-gray-500 w-[1px] h-[85%]"></div>
                  <button
                    className="hover:text-gray-300"
                    onClick={() => showModal({ type: "calendar" })}
                  >
                    Calendar
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
          <div
            ref={menuButtonRef}
            className={classNames(pathname === "/login" && "invisible")}
          >
            {user && (
              <HeaderLoginButton
                displayName={`${user?.given_name || ""} ${
                  user?.family_name?.slice(0, 1) || ""
                }`}
                headshotKey={user?.headshotKey}
                onClick={() =>
                  setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen)
                }
                showMenu={user && !hideMenu}
                role={user?.companyDetails?.name || user?.company || ""}
              />
            )}
          </div>
        </div>
      </div>

      {pageHeader}
      {hasHump && (
        <div className="w-full absolute bottom-0 z-10">
          <img src={HumpImg} alt="" className="w-full" />
        </div>
      )}

      {isSidebarOpen && (
        <div
          ref={sideBarRef}
          className="absolute top-16 right-2 mt-2 text-right z-40 h-fit !bg-white rounded-[12px] shadow-lg transition-transform  px-6 py-3 flex flex-col text-white"
        >
          <div className="block sm:hidden">
            {isAuthorized && (
              <>
                <SidebarLink to="/" setIsSidebarOpen={setIsSidebarOpen}>
                  Job Search
                </SidebarLink>
                <SidebarLink
                  to="/usermanager"
                  setIsSidebarOpen={setIsSidebarOpen}
                >
                  Talent Pool
                </SidebarLink>
                {user.canAccessJobAssistant && (
                  <SidebarLink
                    to="/assistant"
                    setIsSidebarOpen={setIsSidebarOpen}
                  >
                    AI Job Assistant
                  </SidebarLink>
                )}
                {user.canAccessResumeProfileTalentCreation && (
                  <SidebarLink
                    to="/talent-creation"
                    setIsSidebarOpen={setIsSidebarOpen}
                  >
                    Talent Creation
                  </SidebarLink>
                )}
                <div
                  className={classNames("w-full h-px bg-gray-300 my-2", {
                    "block md:hidden": jobOpp?.id,
                  })}
                />

                {jobOpp?.id && (
                  <div
                    className="flex group items-center text-xl"
                    onClick={() => {
                      showModal({ type: "calendar" });
                      setIsSidebarOpen(false);
                    }}
                  >
                    <p className="hover:text-gray-700 hover:bg-[#F1F5F9] text-gray-600 px-6 py-4 w-56 cursor-pointer">
                      Calendar
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="sm:block hidden">
            {user.canAccessJobAssistant && (
              <SidebarLink to="/assistant" setIsSidebarOpen={setIsSidebarOpen}>
                AI Job Assistant
              </SidebarLink>
            )}
            <div className="w-full h-px bg-gray-300 my-2" />
          </div>

          <div className="flex group items-center text-xl" onClick={signOut}>
            <p className="hover:text-gray-700 hover:bg-[#F1F5F9] text-gray-600 px-6 py-4 w-56 cursor-pointer">
              Sign out
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

function SidebarLink(props) {
  return (
    <div
      className="flex group items-center text-xl"
      onClick={() => props.setIsSidebarOpen(false)}
    >
      <NavLink
        to={props.to}
        className="hover:text-gray-700 hover:bg-[#F1F5F9] text-gray-600 px-6 py-4 w-56"
      >
        {props.children}
      </NavLink>
    </div>
  );
}
