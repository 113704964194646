import React, { useContext, useMemo, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { ExternalLink as ExternalLinkIcon } from "lucide-react";

import { JobsContext, AuthContext, AlertContext } from "context/providers";
import { createBullhornExternalLink } from "utils/helpers/bullhorn";
import { formatJobStatus } from "utils/helpers/jobOppDetails";

import {
  JOB_OPPORTUNITY_STATUSES,
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
  CURRENCY_CONVERSION_CONFIG,
} from "lookup";

import HelpText from "./HelpText";

import SvgIcon from "./base/SvgIcon";
import { TABS } from "context/constants/common";
import {
  baseToTargetCurrency,
  loggedUserShouldINRConvert,
} from "utils/helpers/users";

export default function JobListingCard({ job }) {
  const { updateJob, updateJobOppsByJobIdLocally } = useContext(JobsContext);
  const { addGraphQLAlert } = useContext(AlertContext);
  const { user: loggedInUser } = useContext(AuthContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const matches = useMemo(() => {
    const totals = {
      [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: 0,
      [JOB_APPLICATION_MATCH_STATUS.INTERESTED]: 0,
      [JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK]: 0,
      [JOB_APPLICATION_MATCH_STATUS.MATCHED]: 0,
      [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER]: 0,
      [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER]: 0,
      [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]: 0,
      [JOB_APPLICATION_MATCH_STATUS.APPLIED]: 0,
      [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: 0,
      [JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]: 0,
    };

    job.matches.items.forEach((item) => {
      totals[item.status]++;
      if (item.subStatus) {
        totals[item.subStatus]++;
      }
    });

    return totals;
  }, [job.matches.items]);

  const jobDuration = (job) => {
    if (job?.jobLengthInWeeks) {
      return `${job.jobLengthInWeeks} weeks`;
    }

    if (job.jobLength) {
      return `${job?.jobLength} months`;
    }

    return "";
  };

  const statusColor = (() => {
    switch (job.status) {
      case JOB_OPPORTUNITY_STATUSES.ACTIVE:
        return `#5523a2`;
      case JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL:
        return `#e7b73c`;
      default:
        return `black`;
    }
  })();
  const textColor =
    job.status === JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL
      ? "text-black"
      : "text-white";

  const LinkToTab = ({ label, tab }) => (
    <Link
      to={`/jobOpps/${job.id}/details?tab=${tab}`}
      className="hover:text-sky-500"
    >
      <span>{label}</span>
    </Link>
  );

  const updateJobTorcOwner = async () => {
    if (isUpdating) {
      return;
    }
    try {
      setIsUpdating(true);
      const payload = { id: loggedInUser.id, username: loggedInUser.username };
      await updateJob(job.id, { torcOwner: { ...payload } });

      updateJobOppsByJobIdLocally(job.id, "torcOwner", payload);
    } catch (err) {
      addGraphQLAlert(err);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div
      className={classNames(
        "p-0.5 flex shadow-lg relative font-rubik rounded-lg",
        { "animate-pulse": isUpdating }
      )}
    >
      <div className="bg-white w-full">
        <div className="sm:flex">
          <div
            className="relative -top-0.5 -left-0.5 w-40 flex items-center justify-center max-h-14 h-14 rounded-tl-lg"
            style={{ background: statusColor }}
          >
            <span className={classNames("uppercase font-bold", textColor)}>
              {formatJobStatus(job.status)}
            </span>
          </div>

          <div className="ml-6 sm:ml-3 sm:mt-1 mt-8 sm:self-center">
            <div className="font-medium text-grey-800 text-sm">
              <p>
                <span title="Customer company name">
                  {job.organization ||
                    job?.customer?.companyDetails?.name ||
                    job?.customer?.company}{" "}
                  /{" "}
                </span>
                <span title="Customer username">
                  {job.customer?.username} /{" "}
                </span>
                <span title="Customer name">
                  {job.customer?.given_name} {job.customer?.family_name}
                </span>
              </p>
            </div>

            <p className="font-bold text-grey-900 text-xl">{job?.title}</p>

            <div className="text-sky-500 italic font-bold text-sm">
              <p>
                <span>{job.jobType?.title || ""} </span>
                <span>{" / "}</span>
                <span>{jobDuration(job)}</span>
                <span>{" / "}</span>
                <span>
                  {job.minRate ? (
                    <>
                      Hourly Rate: ${job.minRate?.value}
                      &ndash;${job.maxRate?.value}
                      {loggedUserShouldINRConvert(loggedInUser) && (
                        <span className="text-xs">
                          {" "}
                          (
                          {baseToTargetCurrency(
                            job.minRate?.value,
                            CURRENCY_CONVERSION_CONFIG.exchangeFactor
                          )}
                          &ndash;
                          {baseToTargetCurrency(
                            job.maxRate?.value,
                            CURRENCY_CONVERSION_CONFIG.exchangeFactor
                          )}{" "}
                          INR)
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      Yearly Salary: ${job.minSalary?.value}
                      &ndash;${job.maxSalary?.value}
                      {loggedUserShouldINRConvert(loggedInUser) && (
                        <span className="text-xs">
                          {" "}
                          (
                          {baseToTargetCurrency(
                            job.minSalary?.value,
                            CURRENCY_CONVERSION_CONFIG.exchangeFactor
                          )}
                          &ndash;
                          {baseToTargetCurrency(
                            job.maxSalary?.value,
                            CURRENCY_CONVERSION_CONFIG.exchangeFactor
                          )}{" "}
                          INR)
                        </span>
                      )}
                    </>
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="absolute top-4 right-4">
          <Link to={`/jobOpps/${job.id}/details`}>
            <SvgIcon
              type="eyeVisible"
              className="w-[20px] hover:!fill-sky-500"
            />
          </Link>
        </div>

        <div className="w-full pt-4 pb-6 px-10 flex md:w-max justify-between flex-wrap gap-4">
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <LinkToTab label="Shortlisted" tab={TABS.SHORTLISTED} />

              <HelpText text="Profiles which are shortlisted but no emails/contact made with the talent" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED] +
                matches[JOB_APPLICATION_MATCH_STATUS.INTERESTED] +
                matches[JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <LinkToTab label="Matched" tab={TABS.MATCHED} />

              <HelpText text="Selected profiles on which email has been sent to apply for the job. Haven't applied yet" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.MATCHED]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <LinkToTab label="Finalists" tab={TABS.MATCHED} />

              <HelpText text="Profiles selected as finalists to be presented to the customer" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <LinkToTab label="Rejected" tab={TABS.MATCHED} />

              <HelpText text="Profiles rejected by customer" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER] +
                matches[JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER] +
                matches[JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <LinkToTab label="Applied" tab={TABS.MATCHED} />

              <HelpText text="Talent currently applied for the job" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.APPLIED]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <LinkToTab label="Skipped" tab={TABS.SKIPPED} />

              <HelpText text="Skipped profiles" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.SKIPPED]}
            </span>
          </div>
        </div>
        <div className="flex justify-between w-full px-4 pb-2">
          <div className="flex flex-col">
            <p className="text-xs">
              <span className="font-bold">Matcher: </span>
              {job.torcOwner?.username || (
                <span
                  className={classNames("text-sky-500 font-bold text-sm", {
                    "hover:underline cursor-pointer": !isUpdating,
                  })}
                  onClick={() => updateJobTorcOwner()}
                  disabled={isUpdating}
                  title="Claim Job as primary matcher"
                >
                  Claim
                </span>
              )}
            </p>
            <ExternalJobLink externalJobId={job.externalJobId} />
            <p className="text-xs">
              <span className="font-bold">Created Date: </span>
              {`${dayjs(job.createdAt).format("MM/DD/YYYY")}`}
            </p>
          </div>
          <div className="flex flex-col">
            {job.priorityLevel && (
              <div className="flex h-max items-center justify-end">
                <p className="text-xs ">
                  <span className="font-bold">Priority: </span>
                  {job.priorityLevel}
                </p>
              </div>
            )}
            {job.workMode && (
              <div className="flex h-max items-center justify-end text-xs gap-1">
                <span className="font-bold">Mode: </span>
                <p className="lowercase first-letter:uppercase">
                  {job.workMode}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const ExternalJobLink = ({ externalJobId }) => {
  if (!externalJobId) return null;

  const jobBullhornLink = createBullhornExternalLink(
    externalJobId,
    "jobOpportunity"
  );

  if (!jobBullhornLink) {
    return (
      <p className="text-xs">
        <span className="font-bold">External Job Id: </span>
        {externalJobId}
      </p>
    );
  }

  return (
    <p className="text-xs flex gap-1">
      <span className="font-bold">External Job Id: </span>
      <a
        href={jobBullhornLink}
        target="_blank"
        rel="noreferrer"
        className="flex text-xs items-baseline gap-1 transition-all text-sky-500 opacity-90 hover:opacity-100 hover:underline"
      >
        {externalJobId}
        <ExternalLinkIcon size={12} strokeWidth={2.25} />
      </a>
    </p>
  );
};
