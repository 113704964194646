import React, { useContext, useEffect } from "react";
import { connectStateResults } from "react-instantsearch-dom";
import { debounce } from "lodash";

import { SearchContext } from "context/providers";

const searchStalledChangedDebounce = debounce(
  (setSearchStalledChanged, isSearchStalled) => {
    setSearchStalledChanged(isSearchStalled);
  },
  250
);

const ResultsContainer = ({ children, searchResults, isSearchStalled }) => {
  const {
    setHits,
    setDisjunctiveFacets,
    setSearchStalledChanged,
    AISkillSearchConfig,
  } = useContext(SearchContext);

  useEffect(() => {
    setHits(searchResults?.hits || []);
    setDisjunctiveFacets(searchResults?.disjunctiveFacets || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults?.params, searchResults?.index]);

  useEffect(() => {
    if (AISkillSearchConfig.enabled) {
      searchStalledChangedDebounce(setSearchStalledChanged, isSearchStalled);
    }
  }, [isSearchStalled, setSearchStalledChanged, AISkillSearchConfig.enabled]);

  return <>{children}</>;
};

export default connectStateResults(ResultsContainer);
