import classNames from "classnames";

import { Chip } from "components/SearchFilters/components";
import SvgButton from "components/base/SvgButton";
import { ModalContext, SearchContext } from "context/providers";
import {
  EMPLOYMENT_TYPES,
  JOB_OPPORTUNITY_TIME_COMMITMENT,
  LOCATION_FILTER_ACTIONS,
} from "lookup";
import React, { useContext, useMemo } from "react";
import { getCustomerName, formatJobStatus } from "utils/helpers/jobOppDetails";
import { createBullhornExternalLink } from "utils/helpers/bullhorn";
import { JobField } from "components/JobField";
import { useLocation } from "react-router-dom-v5-compat";
import { TABS } from "context/constants/common";
import AIAssistedSkillsSearch from "./molecules/AIAssistedSkillsSearch";

const JobSkills = ({
  title,
  skills,
  definedSkills,
  handleDeleteDefinedSkill,
  handleAddDefinedSkill,
  readOnly,
}) => {
  return (
    <div className="flex flex-col gap-y-3">
      <p className="uppercase text-base text-gray-500 font-bold">{title}</p>
      <div className="flex flex-wrap gap-1">
        {skills?.map((skill) => {
          const isActive = definedSkills.includes(skill.name);

          return (
            <Chip
              className={classNames(
                {
                  "!bg-sky-600 !text-white": isActive && !readOnly,
                },
                { "!text-[#0EA5E9] !border-[#0EA5E9]": !isActive }
              )}
              key={skill.name}
              value={skill.name}
              isActive={isActive}
              onClose={() => handleDeleteDefinedSkill(skill.name)}
              {...(!isActive &&
                !readOnly && {
                  onClick: () => handleAddDefinedSkill(skill.name),
                })}
              readOnly={readOnly}
            />
          );
        })}
      </div>
    </div>
  );
};

const JobDetailsPageHeader = ({
  user,
  jobOpp,
  searchState,
  setSearchState,
}) => {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentTab = params.get("tab");
  const { showModal } = useContext(ModalContext);
  const {
    geographicalRegions,
    locationFilterChange,
    refineLocationCountries,
    AISkillSearchConfig,
  } = useContext(SearchContext);
  const definedSkills = useMemo(
    () => searchState.refinementList?.["skills.name"] || [],
    [searchState.refinementList]
  );
  const timezoneOverlap = useMemo(() => {
    if (jobOpp.timeOverlap > 1 && jobOpp.timeOverlap < 8) {
      return `${jobOpp.timeOverlap} hours`;
    } else if (jobOpp.timeOverlap >= 8) {
      return "All hours";
    }

    return "No Restriction";
  }, [jobOpp.timeOverlap]);

  const setSkills = (skills) => {
    const finalState = {
      ...searchState,
      refinementList: { ...searchState.refinementList, "skills.name": skills },
    };

    setSearchState(finalState);
  };

  const handleDeleteDefinedSkill = (skill) => {
    const skills = definedSkills.filter((el) => el !== skill);

    setSkills(skills);
  };

  const handleAddDefinedSkill = (skill) => {
    const skills = Array.from(new Set([...definedSkills, skill]));

    setSkills(skills);
  };

  const getTimeCommitmentLabel = (jobOpp) => {
    if (jobOpp.timeCommitment === JOB_OPPORTUNITY_TIME_COMMITMENT.FULLTIME) {
      return "Full-time";
    }

    return "Part-time";
  };

  const getJobLength = (jobOpp) => {
    if (jobOpp?.jobLengthInWeeks) {
      return `${jobOpp.jobLengthInWeeks}
          ${jobOpp.jobLengthInWeeks > 1 ? "weeks" : "week"}`;
    }

    if (jobOpp?.jobLength) {
      return `${jobOpp?.jobLength} ${
        jobOpp?.jobLength > 1 ? "months" : "month"
      }`;
    }

    return "";
  };

  const getStartDate = (jobOpp) => {
    const startDate = new Date(jobOpp.startDate);
    if (startDate <= new Date()) {
      return "Immediate";
    }

    const year = startDate.getFullYear();
    const month = (1 + startDate.getMonth()).toString().padStart(2, "0");
    const day = startDate.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  };

  const geographicalRegionsSorted = [
    ...(jobOpp.geographicalRegions || []),
  ]?.sort((a, b) => {
    if (a.countryNames.length === 0 && b.countryNames.length === 0) {
      return 0;
    } else if (a.countryNames.length === 0) {
      return -1;
    }
    return 1;
  });

  const jobLocationFilterIsActive = useMemo(() => {
    if (jobOpp.location) {
      return (
        jobOpp.location?.countryName ===
          searchState.locationFilter?.countryName &&
        jobOpp.location?.cityName === searchState.locationFilter?.cityName &&
        jobOpp.location?.stateName === searchState.locationFilter?.stateName
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.locationFilter]);

  const jobBullhornLink = createBullhornExternalLink(
    jobOpp.externalJobId,
    "jobOpportunity"
  );

  return (
    <div className="min-h-96 w-full px-4 md:px-36 py-12">
      <div className="w-full h-full bg-white flex flex-col gap-y-6 pb-2">
        {jobOpp?.id && (
          <>
            <div className="flex flex-wrap gap-x-2">
              <h1 className="text-lg md:text-4xl font-nexa font-bold mb-2">
                {jobOpp.title}
              </h1>
              <SvgButton
                icon="info"
                className="!h-[30px] !w-[30px] !min-w-[30px] !min-h-[30px]"
                onClick={() => showModal({ type: "jobModal", user })}
              />
            </div>

            <div className="w-fit border-b-2 border-b-gray-300 pb-6 flex justify-start flex-wrap gap-x-0 sm:gap-x-4 md:gap-x-7 lg:gap-x-14 gap-y-4 whitespace-nowrap">
              <JobField label="Job Role" value={jobOpp.jobType?.title} />
              <JobField label="Customer Name" value={getCustomerName(jobOpp)} />
              <JobField
                label="Duration"
                value={`${getTimeCommitmentLabel(jobOpp)} | ${getJobLength(
                  jobOpp
                )}`}
              />
              <JobField label="Start Date" value={getStartDate(jobOpp)} />
              <JobField
                label="Timezone"
                value={jobOpp.timezone?.label || "-"}
              />
              <JobField label="Timezone Overlap" value={timezoneOverlap} />
              {jobOpp.externalJobId ? (
                <JobField
                  label="External Job ID"
                  value={jobOpp.externalJobId}
                  valueHref={jobBullhornLink}
                />
              ) : null}
              <JobField label="Status" value={formatJobStatus(jobOpp.status)} />
              {jobOpp.requiredExperience && (
                <JobField
                  label="Required Overall Experience"
                  value={jobOpp.requiredExperience}
                />
              )}
              {jobOpp.employmentType && (
                <JobField
                  label="Employment"
                  value={EMPLOYMENT_TYPES[jobOpp.employmentType]}
                />
              )}
            </div>

            <div className="flex justify-start flex-wrap gap-x-0 sm:gap-x-4 md:gap-x-7 lg:gap-x-14 gap-y-4 whitespace-nowrap mt-2">
              {jobOpp.geographicalRegions?.length > 0 && (
                <div className="flex flex-col gap-y-3">
                  <p className="uppercase text-base text-gray-500 font-bold">
                    Geographical Regions
                  </p>
                  <div className="flex flex-wrap gap-1 gap-x-2">
                    {geographicalRegionsSorted.map(
                      ({ regionName, countryNames }, index) => {
                        const geographicalRegion = geographicalRegions?.find(
                          (geo) => geo.regionName === regionName
                        );

                        const getRegionInfo = () => {
                          if (countryNames.length > 0) {
                            return countryNames.map((country, i) => (
                              <p key={`${country}_${i}`}>
                                {country}
                                {(i < countryNames.length - 1 ||
                                  index !==
                                    jobOpp.geographicalRegions.length - 1) &&
                                  ", "}
                              </p>
                            ));
                          }

                          if (countryNames.length === 0) {
                            return (
                              <p className="uppercase">
                                {regionName}
                                {index !==
                                  jobOpp.geographicalRegions.length - 1 && ","}
                              </p>
                            );
                          }

                          return (
                            <>
                              <p className="uppercase">{regionName}</p>
                              <p className="text-xs">
                                ({countryNames.slice(0, 2).join(", ")}
                                {countryNames.length > 2 && <>...</>})
                                {index !==
                                  jobOpp.geographicalRegions.length - 1 && ","}
                              </p>
                            </>
                          );
                        };

                        return (
                          <div key={regionName}>
                            <div
                              title={
                                countryNames.length === 0
                                  ? geographicalRegion?.countryNames?.join(", ")
                                  : countryNames.join(", ")
                              }
                              className="flex gap-x-1 items-center"
                            >
                              {getRegionInfo()}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}

              {jobOpp.location && (
                <div className="flex flex-col gap-y-3">
                  <p className="uppercase text-base text-gray-500 font-bold">
                    Location
                  </p>
                  <div className="flex flex-wrap gap-1 gap-x-2">
                    <Chip
                      className={classNames(
                        {
                          "!bg-sky-600 !text-white":
                            jobLocationFilterIsActive &&
                            currentTab !== TABS.IDEALFIT,
                        },
                        {
                          "!text-[#0EA5E9] !border-[#0EA5E9]":
                            !jobLocationFilterIsActive,
                        }
                      )}
                      value={`
                      ${
                        jobOpp.location.cityName &&
                        `${jobOpp.location.cityName},`
                      } ${
                        jobOpp.location.stateName &&
                        `${jobOpp.location.stateName},`
                      } ${jobOpp.location.countryName}`}
                      isActive={jobLocationFilterIsActive}
                      onClose={() => {
                        locationFilterChange(
                          LOCATION_FILTER_ACTIONS.REMOVE,
                          "countryName"
                        );
                      }}
                      {...(!jobLocationFilterIsActive && {
                        onClick: () => {
                          locationFilterChange(LOCATION_FILTER_ACTIONS.ADD, {
                            countryName: jobOpp.location.countryName,
                            stateName: jobOpp.location.stateName,
                            cityName: jobOpp.location.cityName,
                            aroundLatLng:
                              jobOpp.location.latitude &&
                              jobOpp.location.longitude
                                ? `${jobOpp.location.latitude}, ${jobOpp.location.longitude}`
                                : undefined,
                            aroundRadius: 50,
                          });
                          refineLocationCountries([
                            jobOpp.location.countryName,
                          ]);
                        },
                      })}
                      readOnly={currentTab === TABS.IDEALFIT}
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-y-8">
                <div className="flex justify-start flex-wrap gap-x-0 sm:gap-x-4 md:gap-x-7 lg:gap-x-14 gap-y-4 whitespace-nowrap">
                  {jobOpp.skills?.length > 0 && (
                    <JobSkills
                      title="Required Skills"
                      skills={jobOpp.skills}
                      definedSkills={definedSkills}
                      handleDeleteDefinedSkill={handleDeleteDefinedSkill}
                      handleAddDefinedSkill={handleAddDefinedSkill}
                      readOnly={
                        currentTab === TABS.IDEALFIT ||
                        AISkillSearchConfig.enabled
                      }
                    />
                  )}
                  {jobOpp.optionalSkills?.length > 0 && (
                    <JobSkills
                      title="Optional Skills"
                      skills={jobOpp.optionalSkills}
                      definedSkills={definedSkills}
                      handleDeleteDefinedSkill={handleDeleteDefinedSkill}
                      handleAddDefinedSkill={handleAddDefinedSkill}
                      readOnly={
                        currentTab === TABS.IDEALFIT ||
                        AISkillSearchConfig.enabled
                      }
                    />
                  )}
                </div>
                {(!currentTab || currentTab === TABS.TOPCANDIDATES) && (
                  <AIAssistedSkillsSearch />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JobDetailsPageHeader;
