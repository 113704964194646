import { CURRENCY_CONVERSION_CONFIG } from "lookup";

export const convertSalaryToRatePerHour = (salary) => {
  return Math.floor(salary / 2080);
};

export const baseToTargetCurrency = (
  baseValue,
  conversionCurrencyValue,
  internationalFormatText = true,
  intFormat = "en-IN",
  isInteger = true
) => {
  let value;
  if (isInteger) {
    value = parseInt(
      Math.floor(baseValue / conversionCurrencyValue).toFixed(2)
    );
  } else {
    value = Number((baseValue / conversionCurrencyValue).toFixed(2));
  }

  if (internationalFormatText) {
    return new Intl.NumberFormat(intFormat).format(value);
  }

  return value;
};

export const loggedUserShouldINRConvert = (loggedInUser) => {
  return (
    CURRENCY_CONVERSION_CONFIG.exchangeFactor &&
    loggedInUser.company &&
    CURRENCY_CONVERSION_CONFIG.companyNames?.includes(loggedInUser.company)
  );
};

export const getUserName = (user) => {
  return user?.username || user?.identity_username || "";
};
