import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { gtp } from "api/graphql/mutations.js";
import {
  ExternalLink as ExternalLinkIcon,
  SquareArrowOutUpRight as NewTabLinkIcon,
} from "lucide-react";

import {
  formatTalentSource,
  prepareAvailability,
  prepareHourlyRate,
  prepareLocation,
  prepareNoticePeriod,
  prepareSalary,
} from "components/UserCardList/helpers/userCard";

import {
  AuthContext,
  JobsContext,
  ModalContext,
  SearchContext,
} from "context/providers";

import CountryFlag from "components/CountryFlag";
import UserActionIcons from "./UserActionIcons";
import HelpText from "components/HelpText";

import MatchStatusHistoryInfo from "./MatchStatusHistoryInfo";
import CognitoGroups from "./CognitoGroups";
import Referrer from "./Referrer";
import TorcQualityScore from "./TorcQualityScore";
import SvgIcon from "components/base/SvgIcon";
import Button from "components/Button";
import { API } from "aws-amplify";
import {
  baseToTargetCurrency,
  convertSalaryToRatePerHour,
  getUserName,
  loggedUserShouldINRConvert,
} from "utils/helpers/users";
import { CURRENCY_CONVERSION_CONFIG } from "lookup";
import { createBullhornExternalLink } from "utils/helpers/bullhorn";
import { REFERRER_SOURCE } from "lookup";

function SimpleLabelValue({ label, value }) {
  return (
    <p>
      {label}:&nbsp;
      <span className="font-normal">{value}</span>
    </p>
  );
}

const NotificationState = {
  IDLE: "IDLE",
  SENDING: "SENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const UserInfo = ({ hit, collectionKey, activeTabName }) => {
  const { showModal } = useContext(ModalContext);
  const { updateHit } = useContext(SearchContext);
  const [notificationState, setNotificationState] = useState(
    NotificationState.IDLE
  );
  const { user: loggedInUser } = useContext(AuthContext);
  const { jobOpp } = useContext(JobsContext);

  const renderLanguages = (languages) => {
    if (!languages || !languages?.length) {
      return "Not Specified";
    }

    return languages.map((lang, langIndex) => {
      return (
        <span key={lang.language}>
          {langIndex > 0 && ", "}
          {lang.language}
          {lang.level &&
            `(${lang.level?.[0]}${lang.level?.slice(1)?.toLowerCase()})`}
        </span>
      );
    });
  };

  const sendUserNotification = async (hit) => {
    setNotificationState(NotificationState.SENDING);
    try {
      await API.graphql({
        query: gtp,
        variables: { input: { email: hit.email } },
        authMode: "API_KEY",
      });
      setNotificationState(NotificationState.SUCCESS);
    } catch (error) {
      console.error("Error sending user notification:", error);
      setNotificationState(NotificationState.ERROR);
    }
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="mb-2 w-full">
        <div className="flex flex-wrap lg:flex-nowrap justify-between w-full">
          <div>
            <p className="text-text-grey-900 text-2xl whitespace-nowrap font-bold flex justify-between gap-4 items-center">
              {`${hit.given_name} ${hit.family_name}`}
              {hit.hasActivePlacements ? (
                <SvgIcon
                  type="doNotDisturb"
                  title="User has active engagement(s)"
                />
              ) : (
                <></>
              )}
            </p>
          </div>
          <div>
            <UserActionIcons
              hit={hit}
              collectionKey={collectionKey}
              activeTabName={activeTabName}
              updateHit={updateHit}
              showModal={showModal}
            />
            {hit?.reasonsForMatch && (
              <div className="flex justify-end gap-4 mr-2">
                <HelpText
                  iconType="text"
                  iconText="Why Fit?"
                  className="flex justify-start"
                  iconTextClassName="whitespace-nowrap  !text-sky-500"
                >
                  <ul className="max-w-md space-y-1 text-black font-rubik text-normal list-disc list-inside normal-case">
                    {hit.reasonsForMatch.map((el) => {
                      return (
                        <li key={el} className="font-rubik font-normal text-sm">
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                </HelpText>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <p className="gap-2">
            {prepareLocation(hit.location) || hit.locale}{" "}
          </p>
          {hit.location?.countryCode?.toLowerCase() && (
            <CountryFlag
              className="h-[35px] w-[35px] rounded-full p-2"
              country={hit.location.countryCode.toLowerCase()}
              width={48}
              height={36}
            />
          )}
        </div>
        <div className="flex flex-col flex-wrap text-sm text-gray-400">
          <p>{hit.email}</p>
          {hit.phone && <p>{hit.phone.number}</p>}
          <p>
            <a
              className="flex items-baseline gap-1 transition-all text-sky-500 font-bold opacity-70 hover:opacity-100 hover:underline"
              href={`${process.env.REACT_APP_DOMAIN}/#/profile/${getUserName(
                hit
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              @{getUserName(hit)}
              <NewTabLinkIcon size={12} strokeWidth={2.5} />
            </a>
          </p>
          <ExternalUserLink externalUserId={hit.externalUserId} />
          <SimpleLabelValue
            label="Member since"
            value={dayjs(hit.createdAt).format("MM/DD/YYYY")}
          />
          <Referrer referralCode={hit.referrerCode} key={hit.referrerCode} />
          <TorcQualityScore
            torcQualityStats={hit.torcQualityStats}
            torcQualityScore={hit.torcQualityScore}
          />
          {hit.idealFitScore && (
            <SimpleLabelValue
              label="Ideal Fit Score"
              value={Math.trunc(hit.idealFitScore)}
            />
          )}
        </div>
        <div className="flex flex-col gap-2 pl-2 mt-2">
          <MatchStatusHistoryInfo
            hit={hit}
            collectionKey={collectionKey}
            configStatusFields={{
              SKIPPED: {
                title: "Previously Skipped",
                iconType: "cancel",
                hitFieldName: "previouslySkipped",
              },
            }}
          />

          <CognitoGroups hit={hit} collectionKey={collectionKey} />

          {hit.isUnclaimed && (
            <div className="flex gap-2 items-center">
              <div className="flex gap-2 items-center">
                <SvgIcon
                  className="!w-[35px] !h-[35px] !fill-red-600"
                  type="unclaimedUser"
                  title="User has not yet claimed this account"
                />
                {hit.referrerSource === REFERRER_SOURCE.RECRUITERRESUME && (
                  <Button
                    white
                    className="w-fit flex justify-center"
                    onClick={() => sendUserNotification(hit)}
                    disabled={notificationState === NotificationState.SENDING}
                  >
                    {notificationState === NotificationState.SENDING ? (
                      <span className="loader !w-[25px] !h-[25px]"></span>
                    ) : (
                      <SvgIcon
                        className="!w-[25px] !h-[25px]"
                        type="simpleEmail"
                        title="Send confirmation email to claim account"
                      />
                    )}
                  </Button>
                )}
              </div>
              {notificationState === NotificationState.SUCCESS && (
                <p className="text-sm text-green-600">
                  Notification sent successfully
                </p>
              )}
              {notificationState === NotificationState.ERROR && (
                <p className="text-sm text-red-600">
                  An error occurred while sending the notification
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      {hit.ratePerHour?.value > 0 && (
        <p className="text-sm font-bold">
          Hourly Rate:{" "}
          <span className="font-normal text-grey-800">
            {prepareHourlyRate(hit.ratePerHour)}
            {loggedUserShouldINRConvert(loggedInUser) &&
              ` USD | ${baseToTargetCurrency(
                hit.ratePerHour.value,
                CURRENCY_CONVERSION_CONFIG.exchangeFactor
              )} INR`}
          </span>
        </p>
      )}
      {hit.salary?.value > 0 && (
        <p className="text-sm font-bold">
          Salary:{" "}
          <span className="font-normal text-grey-800">
            {prepareSalary(hit.salary)}
            {loggedUserShouldINRConvert(loggedInUser) &&
              ` USD | ${baseToTargetCurrency(
                hit.salary.value,
                CURRENCY_CONVERSION_CONFIG.exchangeFactor
              )} (${baseToTargetCurrency(
                convertSalaryToRatePerHour(hit.salary.value),
                CURRENCY_CONVERSION_CONFIG.exchangeFactor
              )}/hr) INR`}
          </span>
        </p>
      )}
      {!hit.ratePerHour?.value && !hit.salary?.value && (
        <p className="text-sm font-bold">
          Hourly Rate:{" "}
          <span className="font-normal text-grey-800">Not Specified</span>
        </p>
      )}

      <p className="text-sm font-bold">
        Availability:{" "}
        <span className="font-normal text-grey-800">
          {prepareAvailability(hit.availability)}
        </span>
      </p>

      <p className="text-sm font-bold">
        Notice Period:{" "}
        <span className="font-normal text-grey-800">
          {prepareNoticePeriod(hit.noticePeriod)}
        </span>
      </p>

      <p className="text-sm font-bold">
        Known Languages:{" "}
        <span className="font-normal text-grey-800">
          {renderLanguages(hit.knownLanguages)}
        </span>
      </p>

      {jobOpp?.requiredExperience && (
        <p className="text-sm font-bold">
          Years of Experience:{" "}
          <span className="font-normal text-grey-800">
            {hit.overallExperience}
          </span>
        </p>
      )}

      {hit.workAuthorizations && (
        <p className="text-sm font-bold">
          Work Authorizations:{" "}
          <span className="font-normal text-grey-800">
            {hit.workAuthorizations.join(", ")}
          </span>
        </p>
      )}

      {hit?.talentSource && (
        <p className="text-sm font-bold">
          Talent Source & Markup:{"  "}
          <span
            className="font-normal text-grey-800"
            title="Talent markup rounded to 2 decimal places"
          >
            {formatTalentSource(hit)}
          </span>
        </p>
      )}
    </div>
  );
};

const ExternalUserLink = ({ externalUserId }) => {
  if (!externalUserId) return null;

  const userBullhornLink = createBullhornExternalLink(
    externalUserId,
    "userProfile"
  );

  if (!userBullhornLink) {
    return <p>{externalUserId}</p>;
  }

  return (
    <a
      href={userBullhornLink}
      target="_blank"
      rel="noreferrer"
      className="flex items-baseline gap-1 transition-all text-sky-500 opacity-70 hover:opacity-100 hover:underline"
    >
      {externalUserId}
      <ExternalLinkIcon size={12} strokeWidth={2.25} />
    </a>
  );
};

UserInfo.propTypes = {
  hit: PropTypes.object,
};

UserInfo.defaultProps = {
  hit: {},
};

export default UserInfo;
