import React, { createContext, useReducer } from "react";

import { contextConstants } from "context/constants";
import { contextReducers } from "context/reducers";
import Alert from "components/Alert";

export const AlertContext = createContext({});

export const AlertProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contextReducers.alert.reducer,
    contextReducers.alert.initialState
  );

  const generateId = () => {
    return Date.now().toString(36) + Math.floor(Math.random() * 1000);
  };

  const removeAlert = (id) => {
    dispatch({
      type: contextConstants.alert.ALERT_REMOVED,
      payload: { id },
    });
  };

  const addGraphQLAlert = ({ errors }, title, duration = 6000) => {
    if (!errors?.length) {
      return;
    }

    errors.forEach(({ message, path }) => {
      const alert = {
        id: generateId(),
        title:
          title ||
          `Something went wrong during api call${
            path?.[0] ? `: ${path[0]}` : ""
          }`,
        message,
        duration,
      };
      dispatch({
        type: contextConstants.alert.ALERT_ADDED,
        payload: { alert },
      });
    });
  };

  const addCustomAlert = (alert) => {
    dispatch({
      type: contextConstants.alert.ALERT_ADDED,
      payload: { alert: { ...alert, id: generateId() } },
    });
  };

  const clearAlerts = () => {
    dispatch({
      type: contextConstants.alert.ALERTS_CLEARED,
    });
  };

  const alert = {
    ...state,
    addCustomAlert,
    addGraphQLAlert,
    removeAlert,
    clearAlerts,
  };

  return (
    <AlertContext.Provider value={alert}>
      {children}
      <div className="flex flex-col fixed bottom-4 right-4 w-fit gap-2 z-50">
        {state.alerts.map((alert) => (
          <Alert key={alert.id} {...alert} />
        ))}
      </div>
    </AlertContext.Provider>
  );
};
