import React, { createContext, useReducer, useContext, useMemo } from "react";

import {
  CUSTOM_PANEL_DEFAULT_OPERATOR,
  GEOGRAPHICAL_REGIONS_CONFIG,
  LOCATION_FILTER_ACTIONS,
  PLACEMENT_FILTERS,
  SKILL_KEY_NAMES,
  SKILL_STR_FORMATTED_OPTIONS,
  USER_TYPES,
} from "lookup";

import { cloneDeep, difference, union } from "lodash";

import { contextConstants } from "context/constants";
import { contextActions } from "context/actions";
import { contextReducers } from "context/reducers";
import { JobsContext } from "./JobsProvider";
import { AlertContext } from "./AlertProvider";
import { useEffect } from "react";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const { jobOpp } = useContext(JobsContext);
  const { addCustomAlert, addGraphQLAlert } = useContext(AlertContext);

  const [state, dispatch] = useReducer(
    contextReducers.search.reducer,
    contextReducers.search.initialState
  );

  const geographicalRegions = GEOGRAPHICAL_REGIONS_CONFIG;

  const defaultFilters = useMemo(
    () => {
      return {
        ...state.searchState,
        customSearchQuery: "",
        range: {},
        toggle: { agreedToTerms: false },
        refinementList: {
          userType: [USER_TYPES.FREELANCER],
        },
        multiRange: {},
        locationFilterStr: "",
        locationFilter: {},
        configProps: {},
        placementFilterStr: PLACEMENT_FILTERS.notHasActivePlacements,
        yearsOfExperienceFilterStr: "",
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobOpp?.id]
  );

  const setSearchState = (searchState) => {
    if (searchState === undefined) {
      return;
    }
    dispatch({
      type: contextConstants.search.SEARCH_STATE_CHANGED,
      payload: { searchState },
    });
  };

  const handleNoHitsFoundOnSkillStr = () => {
    const lastSkillStr =
      state.searchState?.skillsStr[state.searchState?.skillsStr.length - 1];
    const formatExpLabel = SKILL_STR_FORMATTED_OPTIONS.find(({ value }) =>
      lastSkillStr.includes(value)
    )?.label;
    addCustomAlert({
      title: "Skill Experience",
      variation: "default",
      message: `No results found for: ${
        lastSkillStr.split("_")[0]
      } (${formatExpLabel})`,
      duration: 8000,
      callback: {
        label: "Undo",
        action: () => {
          const skillStrCopy = [...state.searchState.skillsStr];
          skillStrCopy.pop();
          handleSkillsStrChange(skillStrCopy);
        },
      },
    });
  };

  const setHits = (hits) => {
    if (hits.length === 0 && state.searchState?.skillsStr?.length > 0) {
      handleNoHitsFoundOnSkillStr();
    }
    dispatch({
      type: contextConstants.search.HITS_LOADED,
      payload: { hits },
    });
  };

  const replaceHit = (hit) => {
    const hits = contextActions.search.replaceHit(hit, state.hits);

    setHits(hits);
  };

  const setDisjunctiveFacets = (disjunctiveFacets) => {
    dispatch({
      type: contextConstants.search.DISJUNCTIVE_FACETS_CHANGED,
      payload: { disjunctiveFacets },
    });
  };

  const updateHitField = (hitId, fieldKey, value) => {
    const updatedHits = contextActions.search.updateHitFieldByKey(
      state.hits,
      hitId,
      fieldKey,
      value
    );

    const updatedHit = updatedHits.find((hit) => hit.id === hitId);

    dispatch({
      type: contextConstants.search.HITS_LOADED,
      payload: { ...state, hits: updatedHits },
    });

    return updatedHit;
  };

  const updateHit = async (args) => {
    const updatedHit = await contextActions.search.updateHit(args);

    replaceHit(updatedHit);
  };

  const setOperators = (valueKey) => {
    if (Array.isArray(valueKey)) {
      const operators = {};

      valueKey.forEach(
        (el) => (operators[el] = CUSTOM_PANEL_DEFAULT_OPERATOR[el])
      );

      dispatch({
        type: contextConstants.search.OPERATOR_UPDATED,
        payload: { operators },
      });
    } else {
      dispatch({
        type: contextConstants.search.OPERATOR_UPDATED,
        payload: {
          operators: {
            ...state.operators,
            [valueKey]: state.operators[valueKey] === "and" ? "or" : "and",
          },
        },
      });
      if (valueKey === "skills.name") {
        const finalState = {
          ...state.searchState,
          skillsStrFilter: getSkillsStrFilter(
            [...state.searchState.skillsStr],
            state.operators[valueKey] === "and" ? "or" : "and"
          ),
        };
        setSearchState(finalState);
      }
    }
  };

  const setHideSkipped = (hideSkipped) => {
    dispatch({
      type: contextConstants.search.HIDE_SKIPPED_CHANGED,
      payload: { hideSkipped },
    });
  };

  const updateHitsMatchAnalysisHit = (userId, value) => {
    dispatch({
      type: contextConstants.search.HITS_MATCH_ANALYSIS_CHANGED,
      payload: { key: userId, value },
    });
  };

  const removeHitsMatchAnalysisHit = (userId) => {
    dispatch({
      type: contextConstants.search.HITS_MATCH_ANALYSIS_REMOVED,
      payload: { key: userId },
    });
  };

  const clearHitsMatchAnalysis = () => {
    dispatch({
      type: contextConstants.search.HITS_MATCH_ANALYSIS_CLEARED,
    });
  };

  const setHideRejected = (hideRejected) => {
    dispatch({
      type: contextConstants.search.HIDE_REJECTED_CHANGED,
      payload: { hideRejected },
    });
  };

  const setHideMatchedAndApplied = (hideMatchedAndApplied) => {
    dispatch({
      type: contextConstants.search.HIDE_MATCHED_CHANGED,
      payload: { hideMatchedAndApplied },
    });
  };

  // handles location filter change within the widget
  // actions ADD/Replace REMOVE
  const locationFilterChange = (action, location) => {
    let locationFilter = cloneDeep(state.searchState.locationFilter) ?? {};

    if (action === LOCATION_FILTER_ACTIONS.ADD) {
      locationFilter = { ...location };
      refineLocationCountries([location.countryName]);
    }

    if (action === LOCATION_FILTER_ACTIONS.REMOVE) {
      contextActions.search.removeLocationFilter(locationFilter, location);

      if (!locationFilter.countryName) {
        refineLocationCountries([]);
      }
    }

    // city has been cleared - clear aroundLatLng and aroundRadius
    if (!location.cityName && locationFilter.aroundLatLng) {
      delete locationFilter.aroundLatLng;
      delete locationFilter.aroundRadius;
    }

    const locationFilterStr =
      contextActions.search.getLocationFilterStr(locationFilter);

    dispatch({
      type: contextConstants.search.LOCATION_FILTER_CHANGED,
      payload: { locationFilter, locationFilterStr },
    });
  };

  const refineLocationCountries = (countries) => {
    dispatch({
      type: contextConstants.search.COUNTRY_REFINEMENT_CHANGED,
      payload: { countries },
    });
  };

  const setGeographicalRegions = (
    geographicalRegions,
    setSearch = true,
    selectedCountries = [],
    unSelectedCountries = []
  ) => {
    dispatch({
      type: contextConstants.search.GEOGRAPHICAL_REGION_CHANGED,
      payload: { geographicalRegions },
    });

    if (setSearch) {
      let finalState;

      if (selectedCountries.length === 0) {
        let activeCountries = [];

        const existingCountries =
          state.searchState.refinementList?.["location.countryName"] || [];

        if (unSelectedCountries.length > 0) {
          activeCountries = difference(existingCountries, unSelectedCountries);
        } else {
          const activeRegions = geographicalRegions.filter(
            (region) => region.checked
          );

          const regionCountries = activeRegions
            .map(({ countryNames }) => countryNames)
            .flat();

          activeCountries = union(existingCountries, regionCountries);
        }

        finalState = {
          ...state.searchState,
          refinementList: {
            ...state.searchState.refinementList,
            "location.countryName": activeCountries,
          },
        };
      } else {
        finalState = {
          ...state.searchState,
          refinementList: {
            ...state.searchState.refinementList,
            "location.countryName": selectedCountries,
          },
        };
      }

      setSearchState(finalState);
    }
  };

  const setProximitySearch = (updatedRadius) => {
    const locationFilter = {
      ...state.searchState.locationFilter,
      aroundRadius:
        typeof updatedRadius === "number" ? Number(updatedRadius) : null,
    };

    dispatch({
      type: contextConstants.search.LOCATION_FILTER_CHANGED,
      payload: {
        locationFilter,
        locationFilterStr: state.searchState.locationFilterStr,
      },
    });
  };

  const getSkillsStrFilter = (
    skillsStr,
    skillsNameOperator = "AND",
    skillsName = state.searchState.refinementList?.["skills.name"] || []
  ) => {
    let skillsNameFilter = "";
    const obj = {};
    const operator = skillsNameOperator.toUpperCase();

    for (const skillStr of skillsStr) {
      const key = skillStr.split("_")[0];
      if (obj[key]) {
        obj[key] = [...obj[key], `skills_str:"${skillStr}"`];
      } else {
        obj[key] = [`skills_str:"${skillStr}"`];
      }
    }

    const mappedFilter = Object.keys(obj).map((key) => {
      return `(${obj[key].join(" OR ")})`;
    });

    if (operator === "OR") {
      const skillsNameToFilter = skillsName.filter(
        (skName) => !skillsStr.find((skStr) => skStr.startsWith(skName))
      );
      skillsNameFilter = skillsNameToFilter
        .map((skName) => {
          return `skills.name:"${skName}"`;
        })
        .join(` ${operator} `);
    }

    return !!mappedFilter.length
      ? ` AND ${mappedFilter.join(` ${operator} `)}${
          skillsNameFilter && ` OR ${skillsNameFilter}`
        }`
      : "";
  };

  const handleSkillsStrChange = (skillsStr, skillsName) => {
    const skillsNameOperator = state.operators["skills.name"];
    const finalState = {
      ...state.searchState,
      refinementList: {
        ...state.searchState.refinementList,
      },
      skillsStr,
      skillsStrFilter: getSkillsStrFilter(
        skillsStr,
        skillsNameOperator,
        skillsName
      ),
    };

    if (state.searchState.refinementList) {
      const skillsNameRefinement =
        state.searchState.refinementList[SKILL_KEY_NAMES.name] || [];

      const skillsToBeActive = skillsStr
        .map((item) => {
          if (!skillsNameRefinement.some((sk) => item.startsWith(sk))) {
            return item.split("_")[0];
          }
          return null;
        })
        .filter((e) => e);
      if (!!skillsToBeActive.length) {
        finalState.refinementList[SKILL_KEY_NAMES.name] = [
          ...skillsNameRefinement,
          ...skillsToBeActive,
        ];
      }
    }

    setSearchState(finalState);
  };

  const clearLocationFilters = () => {
    setGeographicalRegions(geographicalRegions, false);

    const finalState = {
      ...state.searchState,
      locationFilter: {},
      locationFilterStr: "",
      refinementList: {
        ...state.searchState.refinementList,
        "location.countryName": [],
      },
    };

    setSearchState(finalState);
  };

  const AISkillsConfigSearchChange = (newValue) => {
    dispatch({
      type: contextConstants.search.AI_SKILL_SEARCH_CONFIG_CHANGED,
      payload: {
        AISkillSearchConfig: { ...newValue },
      },
    });
  };

  const setSearchStalledChanged = (isSearchStalled) => {
    dispatch({
      type: contextConstants.search.SEARCH_STALLED_CHANGED,
      payload: {
        isSearchStalled,
      },
    });
  };

  const clearSearchState = ({ isUnMounting }) => {
    setGeographicalRegions(geographicalRegions, false);

    const clonedDefaultFilters = cloneDeep(defaultFilters);

    if (!isUnMounting) {
      clonedDefaultFilters.configProps = {
        ...state.searchState?.configProps,
        optionalWords: "",
      };
    }

    if (!jobOpp.id) {
      clonedDefaultFilters.placementFilterStr = "";
    }

    setSearchState(clonedDefaultFilters);
    setOperators(Object.keys(state.operators));
  };

  const clearAISkillsSearchConfig = () => {
    dispatch({
      type: contextConstants.search.AI_SKILL_SEARCH_CONFIG_CHANGED,
      payload: {
        AISkillSearchConfig: {
          enabled: false,
          loading: false,
          data: {},
        },
      },
    });
  };

  function _formatAISkills(skillString, wrapPhrase = true) {
    if (!skillString) {
      return "";
    }

    if (wrapPhrase) {
      return skillString
        .split(/\s*,\s*/) // Split by commas with optional surrounding whitespace
        .map((skill) => skill.trim()) // Remove extra spaces around each skill
        .map((skill) => (/\s/.test(skill) ? `"${skill}"` : skill)) // Wrap multi-word skills in quotes
        .join(" ");
    }

    return skillString
      .split(/\s*,\s*/) // Split by commas with optional surrounding whitespace
      .map((skill) => skill.trim()) // Remove extra spaces around each skill
      .join(" ");
  }

  const updateAISkillConfig = (config) => {
    dispatch({
      type: contextConstants.search.AI_SKILL_SEARCH_CONFIG_CHANGED,
      payload: { AISkillSearchConfig: config },
    });
  };

  const fetchAndUpdateAISkillsSearch = async (
    enabled,
    refresh,
    noCache = false
  ) => {
    // Set initial loading state
    const newConfig = {
      ...state.AISkillSearchConfig,
      enabled,
      loading: enabled,
    };
    updateAISkillConfig(newConfig);

    let AISkillsForSearch = {};
    const shouldUseExistingSkills =
      (!state.AISkillSearchConfig.enabled || refresh) &&
      (state.AISkillSearchConfig?.data?.requiredSkills ||
        state.AISkillSearchConfig?.data?.optionalSkills);

    if (shouldUseExistingSkills) {
      AISkillsForSearch = { ...state.AISkillSearchConfig?.data };
    } else {
      try {
        const { data } =
          await contextActions.search.getJobOpportunityAISkillsForSearch(
            jobOpp?.id,
            noCache
          );
        AISkillsForSearch = JSON.parse(data.getJobOpportunityAISkillsForSearch);
        AISkillsForSearch.requiredSkills = _formatAISkills(
          AISkillsForSearch.requiredSkills || ""
        );
        AISkillsForSearch.optionalSkills = _formatAISkills(
          AISkillsForSearch.optionalSkills || "",
          false
        );
      } catch (err) {
        updateAISkillConfig({ ...newConfig, loading: false });
        addGraphQLAlert(err);
        return;
      }
    }

    updateAISkillConfig({
      ...newConfig,
      data: AISkillsForSearch,
      loading: false,
    });

    return {
      ...cloneDeep(state.searchState),
      customSearchQuery: AISkillsForSearch.requiredSkills || "",
      configProps: {
        queryType: "prefixAll",
        optionalWords: AISkillsForSearch.optionalSkills,
        advancedSyntax: true,
        removeWordsIfNoResults: "lastWords",
      },
      refinementList: {
        ...state.searchState.refinementList,
      },
    };
  };

  const handleSkillSearchEnabledChange = async (
    enabled,
    refresh,
    noCache = false
  ) => {
    if (!enabled) {
      dispatch({
        type: contextConstants.search.AI_SKILL_SEARCH_CONFIG_CHANGED,
        payload: {
          AISkillSearchConfig: {
            ...state.AISkillSearchConfig,
            enabled,
            loading: enabled,
          },
        },
      });
    }

    const newSearchState = enabled
      ? await fetchAndUpdateAISkillsSearch(enabled, refresh, noCache)
      : {
          ...cloneDeep(state.searchState),
          customSearchQuery: "",
          configProps: {
            queryType: "prefixLast",
            optionalWords: "",
            advancedSyntax: false,
            removeWordsIfNoResults: "none",
          },
          refinementList: {
            ...state.searchState.refinementList,
            "skills.name":
              jobOpp?.skills?.length > 0
                ? jobOpp.skills.map((e) => e.name)
                : undefined,
          },
        };

    setSearchState(newSearchState);
  };

  const search = {
    searchState: state.searchState,
    hits: state.hits,
    operators: state.operators,
    hideSkipped: state.hideSkipped,
    hideRejected: state.hideRejected,
    hideMatchedAndApplied: state.hideMatchedAndApplied,
    geographicalRegions: state.geographicalRegions,
    locationFilter: state.searchState.locationFilter ?? {},
    disjunctiveFacets: state.disjunctiveFacets,
    hitsMatchAnalysis: state.hitsMatchAnalysis,
    AISkillSearchConfig: state.AISkillSearchConfig,
    isSearchStalled: state.isSearchStalled,
    setSearchState,
    setHits,
    setProximitySearch,
    updateHitField,
    updateHit,
    setOperators,
    clearSearchState,
    setHideSkipped,
    setHideRejected,
    setHideMatchedAndApplied,
    locationFilterChange,
    clearLocationFilters,
    refineLocationCountries,
    setGeographicalRegions,
    handleSkillsStrChange,
    setDisjunctiveFacets,
    updateHitsMatchAnalysisHit,
    removeHitsMatchAnalysisHit,
    clearHitsMatchAnalysis,
    handleSkillSearchEnabledChange,
    clearAISkillsSearchConfig,
    AISkillsConfigSearchChange,
    fetchAndUpdateAISkillsSearch,
    setSearchStalledChanged,
  };

  useEffect(() => {
    // initialize predefined regions
    setGeographicalRegions(geographicalRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchContext.Provider value={search}>{children}</SearchContext.Provider>
  );
};
