import React, { createContext, useEffect, useReducer } from "react";

import api from "apiSingleton";

import { contextConstants } from "context/constants";
import { contextReducers } from "context/reducers";
import { contextActions } from "context/actions";
import moment from "moment";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contextReducers.auth.reducer,
    contextReducers.auth.initialState
  );

  const initUser = async () => {
    let user = await contextActions.auth.initUser();

    if (user.id) {
      const { data } = await api.user.getMe();
      user = { ...user, ...data.getMe };
    }

    user.canAccessApplication =
      user.attributes.groups.includes(
        process.env.REACT_APP_COGNITO_ADMIN_GROUP
      ) ||
      user.attributes.groups.includes(
        process.env.REACT_APP_COGNITO_GROUP_USER_MANAGERS
      );

    user.canAccessJobAssistant = user.attributes.groups.includes(
      process.env.REACT_APP_COGNITO_GROUP_ALLOW_JOB_ASSISTANT
    );

    user.canFetchIdealCandidates = user.attributes.groups.includes(
      process.env.REACT_APP_COGNITO_GROUP_ALLOW_FETCH_IDEAL // I think I can add _CANDIDATES but I may hit length limit
    );

    user.canAccessResumeProfileTalentCreation = user.attributes.groups.includes(
      process.env.REACT_APP_COGNITO_GROUP_ALLOW_RESUME_PROFILE
    );

    dispatch({
      type: contextConstants.auth.USER_UPDATED,
      payload: { user },
    });
  };

  const auth = {
    ...state,
  };

  useEffect(() => {
    (async () => {
      await initUser();
    })();
  }, []);

  useEffect(() => {
    if (auth.user?.attributes?.sub && window.analytics?.identify) {
      window.analytics.identify(auth.user.attributes.sub, {
        username: auth.user.username,
        logInDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      });
    }
  }, [auth.user?.attributes?.sub, auth.user?.username]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
