import React, { useContext, useEffect, useMemo } from "react";
import { Amplify } from "aws-amplify";
import { ThemeProvider, Authenticator } from "@aws-amplify/ui-react";
import { HashRouter, Switch, Redirect } from "react-router-dom";
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";
import { ToastContainer } from "react-toastify";

import RootProvider, { AuthContext, JobsContext } from "context/providers";
import awsConfig from "./aws-exports";

import { SearchContainer, ModalContainer } from "containers";
import UserSearch from "pages/UserSearch";
import JobOpportunities from "pages/JobOppList";
import JobOppDetails from "pages/JobOppDetails";

import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";

import AppLogo from "components/Logo";
import Header from "components/Header";
import JobAssistant from "components/JobAssistant";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "apollo";
import PageViewTracker from "components/PageViewTracker";
import { getUserName } from "utils/helpers/users";
import TalentCreation from "pages/TalentCreation";

function urlMatchesLocation(url) {
  if (window.location.href.includes(url)) {
    return url;
  }

  return null;
}

awsConfig.oauth.domain =
  process.env.REACT_APP_COGNITO_HOSTED_DOMAIN || awsConfig.oauth.domain;

const aAcceptabelSignInURLs = awsConfig.oauth.redirectSignIn.split(",");
const aAcceptableSignOutURLs = awsConfig.oauth.redirectSignOut.split(",");

awsConfig.oauth.redirectSignIn = aAcceptabelSignInURLs.find(urlMatchesLocation);
awsConfig.oauth.redirectSignOut =
  aAcceptableSignOutURLs.find(urlMatchesLocation);

// More details about cookieStorage config see link
// https://docs.amplify.aws/javascript/prev/build-a-backend/auth/set-up-auth/#set-up-and-connect-backend-resources
// "Set up your Auth backend resources" -> "Existing Resources"
awsConfig.Auth = {
  ...awsConfig.Auth,
  cookieStorage: {
    domain: window.location.hostname,
    sameSite: "strict",
    secure: true,
  },
};

Amplify.configure(awsConfig);

const components = {
  SignIn: {
    Header() {
      return (
        <div className="flex justify-center pt-8">
          <AppLogo />
        </div>
      );
    },
  },
};

const PrivateRoute = ({
  component: Component,
  signOut,
  isAssistantRoute,
  ...rest
}) => {
  const { user } = useContext(AuthContext);
  const { jobOpp } = useContext(JobsContext);

  const loading = useMemo(() => !user, [user]);

  if (loading) {
    return (
      <>
        <Header signOut={signOut} user={user} jobOpp={jobOpp} />
        <div className="flex justify-center mt-8">
          <span className="loader"></span>
        </div>
      </>
    );
  }

  if (
    (isAssistantRoute && !user.canAccessJobAssistant) ||
    (rest.path === "/talent-creation" &&
      !user.canAccessResumeProfileTalentCreation)
  ) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <CompatRoute
      key={rest.computedMatch.url}
      {...rest}
      render={(props) =>
        user.canAccessApplication ? (
          <>
            <Header
              {...props}
              signOut={signOut}
              user={user}
              jobOpp={jobOpp}
              isAuthorized={user.canAccessApplication}
            />
            {<Component user={user} />}
          </>
        ) : (
          <>
            <Header
              {...props}
              signOut={signOut}
              user={user}
              jobOpp={jobOpp}
              isAuthorized={user.canAccessApplication}
            />
            <div className="text-center pt-8">
              <p className="text-lg font-semibold text-gray-500">
                @{getUserName(user)}{" "}
                <span className="text-red-400">
                  You are not authorized to use this service. Please contact
                  administrator if you think this is a mistake.
                </span>
              </p>
            </div>
          </>
        )
      }
    />
  );
};

export default function App() {
  useEffect(() => {
    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PMVDVJWM');
    `;
    document.head.appendChild(gtmScript);
  }, []);

  return (
    <ThemeProvider>
      <Authenticator
        socialProviders={["google"]}
        hideSignUp={true}
        components={components}
      >
        {({ signOut }) => (
          <>
            <RootProvider>
              {/*
                Once RootProvider gets refactored to remove unneceesary providers, ApolloProvider can encapsulate it
              */}
              <ApolloProvider client={apolloClient}>
                <HashRouter>
                  <CompatRouter>
                    <PageViewTracker />
                    <Switch>
                      <PrivateRoute
                        path="/"
                        exact
                        signOut={signOut}
                        component={JobOpportunities}
                      />
                      <PrivateRoute
                        path="/assistant"
                        exact
                        signOut={signOut}
                        component={JobAssistant}
                        isAssistantRoute
                      />
                      <PrivateRoute
                        signOut={signOut}
                        component={() => (
                          <SearchContainer>
                            <UserSearch />
                          </SearchContainer>
                        )}
                        path="/usermanager"
                        exact
                      />
                      <PrivateRoute
                        signOut={signOut}
                        component={() => (
                          <SearchContainer>
                            <JobOppDetails />
                          </SearchContainer>
                        )}
                        path="/jobOpps/:id/details"
                        exact
                      />
                      <PrivateRoute
                        signOut={signOut}
                        component={TalentCreation}
                        path="/talent-creation"
                      />
                    </Switch>
                  </CompatRouter>
                </HashRouter>
              </ApolloProvider>

              <ModalContainer />
            </RootProvider>

            <ToastContainer autoClose={3000} theme="dark" />
          </>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}
