import classNames from "classnames";
import { useState, useMemo } from "react";

import Button from "components/Button";
import { parseBullhornMetadata } from "utils/helpers/bullhorn";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHead,
  TableCell,
} from "components/ui/table";

function BullhornMetadata({ metadata, borderTop, showTitle, className, type }) {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <ul
      className={classNames(
        "font-rubik mb-4 pb-2 leading-snug flex flex-col gap-2",
        className,
        { "mt-4": !showTitle }
      )}
    >
      {borderTop && <hr className="w-full border-t-2 border-gray-300 mb-4" />}
      {showTitle && (
        <div className="text-blue-400 font-nexa text-xl flex  items-center justify-between">
          <p>Bullhorn Metadata</p>
          <Button
            onClick={() => {
              setIsHidden((current) => !current);
            }}
            className="block md:hidden font-normal text-sm"
          >
            {isHidden ? "Open" : "Close"}
          </Button>
        </div>
      )}
      <Metadata unparsedMetadata={metadata} hidden={isHidden} type={type} />
    </ul>
  );
}

function Metadata({ unparsedMetadata, hidden, type }) {
  const metadata = useMemo(
    () => parseBullhornMetadata(unparsedMetadata),
    [unparsedMetadata]
  );

  if (type === "table") {
    return <MetadataTable metadata={metadata} />;
  }

  if (!metadata || metadata.length === 0) {
    return (
      <p className={classNames("mb-4", { "hidden md:block": hidden })}>
        No bullhorn metadata provided
      </p>
    );
  }

  return metadata.map(({ key, value }) => {
    return (
      <li
        key={key}
        className={classNames("flex flex-wrap gap-1", {
          "hidden md:block": hidden,
        })}
      >
        <p className="font-bold">{key}:</p>
        <pre className="w-full overflow-x-auto whitespace-pre-wrap font-rubik">
          {value}
        </pre>
      </li>
    );
  });
}

function MetadataTable({ metadata }) {
  return (
    <section className="max-h-96 h-96 overflow-y-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Key</TableHead>
            <TableHead>Value</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {metadata.map((item) => (
            <TableRow key={item.key}>
              <TableCell>{item.key}</TableCell>
              <TableCell>
                <pre className="w-full overflow-x-auto whitespace-pre-wrap font-rubik">
                  {item.value}
                </pre>
              </TableCell>
            </TableRow>
          ))}
          {metadata.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                No bullhorn metadata provided
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </section>
  );
}

export default BullhornMetadata;
