import Popover from "components/ui/Popover";
import { TriangleAlert } from "lucide-react";

const HitBadDataError = ({ apiErrors = [] }) => {
  return (
    <div className="pl-[148px] flex gap-2">
      <Popover
        className="md:!min-w-[500px] max-w-min md:max-w-[600px] pt-0 outline-none"
        icon={
          <span
            title="View Errors"
            className="flex justify-center w-12 h-12 hover:bg-gray-200 p-2.5 rounded-full"
          >
            <TriangleAlert color="#ff0000" size={28} />
          </span>
        }
      >
        <div className="flex flex-col gap-[7px]">
          <div className="flex justify-between bg-red-500 p-4">
            <p className="font-medium text-white">
              Data Error{apiErrors.length > 1 && "s"}
            </p>
          </div>
          <div className="flex flex-col gap-y-2 px-4 py-2 min-h-[25px] max-w-fit break-all">
            {apiErrors.map(({ message }) => (
              <label className="bg-gray-100 p-2">{message}</label>
            ))}
          </div>
        </div>
      </Popover>
      <div>
        <p className="max-w-md text-red-500">
          Candidate wasn't fetched successfully
        </p>
        <p className="max-w-md text-red-500">
          Please contact administrator about this issue
        </p>
      </div>
    </div>
  );
};

export default HitBadDataError;
