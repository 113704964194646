import api from "apiSingleton";
import Button from "components/Button";
import SvgButton from "components/base/SvgButton";
import { useState, useRef, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import { API, graphqlOperation } from "aws-amplify";
import { getCloudinarySignature } from "api/graphql/queries";
import { AuthContext, JobsContext } from "context/providers";

const getRandomSuffix = () => {
  return new Array(4)
    .fill(9)
    .map((num) => Math.round(Math.random() * num))
    .join("");
};

const domain = process.env.REACT_APP_DOMAIN;

export const TalentCreation = ({ jobOpportunityId, userMatched }) => {
  const { user } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [userExists, setUserExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState({
    status: null,
    message: null,
  });
  const [createdUser, setCreatedUser] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const cloudinaryWidget = useRef();
  const identityUsername = useRef(null);

  const emailIsAvailable = async () => {
    setLoading(true);
    const user = await api.user.getByEmail({ email, limit: 1 });
    setLoading(false);

    const isAvailable = user?.data?.getUserByEmail?.items?.length === 0;
    setUserExists(!isAvailable);
    setShowUpload(isAvailable);
  };

  const onEmailChange = (e) => {
    if (subscription) {
      return;
    }
    setEmail(e.target.value);
    setUserExists(false);
    setLoading(true);
  };

  const showUploadWidget = async () => {
    if (!email.match(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      return null;
    }

    if (subscription) {
      subscription.unsubscribe();
    }
    const identity_username = `rm_${email
      .split("@")[0]
      .replaceAll(/[^a-zA-Z0-9]/g, "")}${getRandomSuffix()}`;

    identityUsername.current = identity_username;
    const context = {
      email,
      creatorId: user.id,
      identity_username,
    };

    if (jobOpportunityId) {
      context.jobOpportunityId = jobOpportunityId;
    }
    const notificationSubscription = await api.user.onUserResumeDataExtraction({
      identity_username,
    });
    setLoading(true);
    if (notificationSubscription) {
      const sub = notificationSubscription.subscribe({
        next: ({ value }) => {
          const message = value?.data?.onUserResumeDataExtraction?.data;

          try {
            const data = JSON.parse(message);
            setNotificationMessage(data);
            if (data.status === "resume_parse_process_successful") {
              setCreatedUser(true);
              setLoading(false);
            }
            if (data.status === "user_created") {
              setProfileUrl(`${domain}/#/profile/${identityUsername.current}`);
            }

            if (data.status === "match_created" && userMatched) {
              userMatched.current = true;
            }
          } catch (e) {
            setNotificationMessage({
              status: "error",
              message:
                "Error happened while grabbing notification. See console for error",
            });
            console.log(`Notification failed: ${value}`);
            console.error(e);
          }
        },
        error: (error) => console.log("subscribe error", error),
      });
      setSubscription(sub);
    } else {
      setNotificationMessage({
        type: "error",
        message: "failed to connect to notification server",
      });
    }
    cloudinaryWidget.current = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_RESUME,
        sources: ["local"],
        clientAllowedFormats: [
          "PDF",
          "doc",
          "docx",
          "docm",
          "dotx",
          "rtf",
          "txt",
          "xls",
          "xlsx",
          "xlsm",
          "pot",
          "potm",
          "potx",
          "pps",
          "ppsm",
          "pptx",
          "ppt",
          "pptm",
        ],
        multiple: false,
        maxFileSize: 6291456,
        language: "en",
        publicId: `${identity_username}_resume`,
        context,
        prepareUploadParams: (cb, { public_id, upload_preset, context }) => {
          API.graphql(
            graphqlOperation(getCloudinarySignature, {
              publicId: public_id,
              preset: upload_preset,
              context,
            })
          )
            .then((response) => {
              cb(response.data.getCloudinarySignature);
            })
            .catch((e) => {
              alert("Something Went Wrong, Please Try Again");
              cb({ cancel: true });
            });
        },
      },
      (err, result) => {
        if (err) {
          console.log("Error loading upload widget", err);
          if (err.statusText) {
            alert(err.statusText);
          }

          return;
        }

        if (result.event === "abort") {
          if (subscription) {
            subscription.unsubscribe();
          }
          setSubscription(null);
          setShowUpload(true);
          setLoading(false);
        }

        if (result.info === "hidden") {
          document.body.style.overflow = "visible";
        }

        if (result.event === "queues-end") {
          if (result.info?.files?.[0]?.status === "success") {
          } else {
            cloudinaryWidget.current.close();
          }
        }
        return;
      }
    );
    if (cloudinaryWidget.current) {
      cloudinaryWidget.current.open();
    }
  };

  useEffect(() => {
    if (email.match(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      const timeout = setTimeout(() => emailIsAvailable(), 700);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (subscription) {
      subscription.unsubscribe();
      setSubscription(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdUser]);

  useEffect(() => {
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="my-20 flex justify-center">
      <div className="max-w-lg p-4">
        <h2 className="text-xl font-bold">Talent Creation</h2>
        <p className="mt-2">
          Please enter the talent's email below to see whether there is already
          a profile in the TORC platform.
        </p>
        <div className="flex flex-col">
          <label htmlFor="email-input" className="mt-8 font-bold">
            {" "}
            E-mail
          </label>
          <input
            id="email-input"
            type="text"
            className="border-black p-4 border-2 rounded-md"
            placeholder="Enter talent's email"
            value={email}
            onChange={onEmailChange}
          />
          {userExists && <p className="text-red-400">Email already in use</p>}
        </div>
        {showUpload && !loading && !createdUser && (
          <>
            <h5 className="text-lg font-bold mt-8">Add resume</h5>
            <p>
              Upload the Talent's resume to automatically fill in their profile
              details{jobOpportunityId ? " and match them to this job" : ""}.
              They'll be able to review and complete any additional information
              afterward.
            </p>
            <div className="flex justify-center mt-8">
              <Button onClick={showUploadWidget}>Upload Resume</Button>
            </div>
          </>
        )}
        {notificationMessage.message && (
          <div className="flex justify-center mt-8">
            {notificationMessage.message}
          </div>
        )}
        {profileUrl && (
          <div className="mt-2 flex justify-center">
            <a
              className="text-blue hover:underline"
              href={profileUrl}
              target="_blank"
              rel="noreferrer"
            >
              {email}'s Profile
            </a>
          </div>
        )}
        {subscription && !createdUser && (
          <div className="flex justify-center mt-8">
            <span className="loader"></span>
          </div>
        )}
      </div>
    </section>
  );
};

const TalentCreationModal = ({ jobOpportunityId, className }) => {
  const { jobOpp, clearJob, initJob } = useContext(JobsContext);
  const [open, setOpen] = useState(false);

  const onClose = (matched) => {
    if (matched && jobOpp && jobOpp.id) {
      clearJob(jobOpp.id);
      initJob(jobOpp.id);
    }

    setOpen(false);
  };
  return (
    <>
      <Modal
        jobOpportunityId={jobOpportunityId}
        onClose={onClose}
        open={open}
      />
      <SvgButton
        onClick={() => setOpen(true)}
        icon="resumeIcon"
        className={className}
      />
    </>
  );
};

const Modal = ({ jobOpportunityId, onClose, open }) => {
  const userMatched = useRef();

  if (!open) {
    return null;
  }

  return createPortal(
    <div
      className="fixed inset-0 grid place-items-center"
      style={{
        background: `rgba(0, 0, 0, 0.3)`,
        height: "100%",
        width: "100%",
        zIndex: "150",
      }}
    >
      <div
        className="overflow-y-auto bg-white rounded relative"
        style={{ maxHeight: "95%", width: "100%", maxWidth: "700px" }}
      >
        <button
          className="absolute top-6 right-8 hover:underline"
          onClick={() => onClose(userMatched.current)}
        >
          Close
        </button>
        <TalentCreation
          jobOpportunityId={jobOpportunityId}
          userMatched={userMatched}
        />
      </div>
    </div>,
    document.body
  );
};

export default TalentCreationModal;
