import React, { Fragment, useMemo, useState } from "react";
import dayjs from "dayjs";

import SvgIcon from "components/base/SvgIcon";
import SvgButton from "components/base/SvgButton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { getPurifiedParsedMarkDown } from "utils/markdown";
import { NOTE_TYPES_MAPPED_ICON } from "lookup";

const Note = ({
  isPublic,
  creator,
  content,
  shouldRenderSeparator,
  isAllowToDelete,
  onDelete,
  noteType,
  metadata,
  noteSubType,
  ...rest
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const prepareDate = (createdAt) => {
    if (!createdAt) return "";
    const createdDaysAgo = dayjs().diff(createdAt, "days");
    const dateFormat =
      createdDaysAgo >= 2 ? "MMM DD YYYY" : "MMM DD YYYY, hh:mm";

    return dayjs(createdAt).format(dateFormat);
  };

  const handleDeleteClick = () => {
    const confirmDeleting = window.confirm(
      "Are you sure you want to delete the note?"
    );

    if (confirmDeleting) {
      setIsDeleting(true);
      onDelete(rest.id);
    }
  };

  const renderDeleteUi = () => {
    return (
      <SvgButton
        icon="cross"
        iconProps={{ fill: "tomato", title: "Delete this note?" }}
        onClick={handleDeleteClick}
        isLoading={isDeleting}
      />
    );
  };

  const noteTypeIcon = NOTE_TYPES_MAPPED_ICON[noteType];

  const bullhornMetadata = useMemo(() => {
    if (!metadata) {
      return {};
    }

    try {
      const parsedMetadata = JSON.parse(metadata);
      return JSON.parse(parsedMetadata.BULLHORN || "{}");
    } catch (e) {
      console.error(`Failed to parse bullhorn metadata: \n ${e}`);
      return {};
    }
  }, [metadata]);

  return (
    <Fragment>
      <div className="flex my-2 py-2">
        <div className="flex flex-col items-center mr-2">
          <SvgIcon
            type={isPublic ? "notePublic" : "notePrivate"}
            className="font-red-500 min-w-[24px] min-h-[24px] w-[24px] h-[24px]"
          />
        </div>

        <div className="flex flex-col justify-between w-full gap-2">
          <div className="flex justify-between">
            <p className="text-xs">{creator || "user"}</p>

            <span className="text-xs whitespace-nowrap">
              {prepareDate(rest.createdAt)}
            </span>
          </div>

          <div className="w-full flex justify-between items-center">
            <div
              className="w-full"
              dangerouslySetInnerHTML={{
                __html: getPurifiedParsedMarkDown(content),
              }}
            />

            {isAllowToDelete && renderDeleteUi()}
          </div>

          <div className="flex justify-end gap-2">
            {noteTypeIcon && (
              <div className="flex justify-end items-center">
                <SvgIcon type={noteTypeIcon.icon} className="w-[18px] mr-1" />
                <span className="text-xs">{noteTypeIcon.label}</span>
              </div>
            )}
            {noteSubType && (
              <div className="flex justify-end items-center">
                <SvgIcon type={"note"} className="w-[18px] mr-1" />
                <span className="text-xs">{noteSubType}</span>
              </div>
            )}
            <div className="flex justify-end items-center">
              {(() => {
                const iconType = isPublic ? "horn" : "eyeClosed";
                const text = isPublic ? "Visible to customer" : "Private";

                return (
                  <>
                    <SvgIcon type={iconType} className="w-[18px] mr-1" />
                    <span className="text-xs">{text}</span>
                  </>
                );
              })()}
            </div>
          </div>
          {noteType === "BULLHORN" && bullhornMetadata && (
            <div className="flex flex-wrap text-xs justify-end gap-1">
              {(() => {
                const createdById = bullhornMetadata?.["Created_By_Id__c"];
                const createdByEmail =
                  bullhornMetadata?.["Created_By_Email__c"];
                const dateAdded = bullhornMetadata?.["Date_Added__c"];
                const hasCreatedBy = createdById || createdByEmail;

                return (
                  <>
                    {hasCreatedBy && (
                      <p className="border-r-[1px] border-gray-700 pr-1.5">
                        Created By: {createdById}{" "}
                        {createdByEmail && `(${createdByEmail})`}
                      </p>
                    )}
                    {dateAdded && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <p className="pl-0.5">
                              Bullhorn Created Date: {prepareDate(dateAdded)}
                            </p>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{dateAdded}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </>
                );
              })()}
            </div>
          )}
        </div>
      </div>

      {shouldRenderSeparator && <hr className="w-full border-t border-black" />}
    </Fragment>
  );
};

export default Note;
